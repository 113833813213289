:root {
  --regular-blue: #6b9aad;
  --blue: #224453;
  --pink: #fea47e;
  --pink2: #fea17c;
  --white: #ffffff;
  --light-blue: #ecf1f7;
  --orange: rgba(254, 161, 124, 1);
  --light-orange: rgba(255, 251, 250);
  --green: rgba(145, 211, 188, 1);
  --light-green: rgba(252, 255, 254);
  --purlple: rgba(205, 126, 242, 1);
  --dark-purple: rgba(112, 126, 224, 1);
  --light-purple: rgba(254, 253, 255);
  --yellow: rgba(255, 186, 73, 1);
  --light-yellow: rgba(255, 252, 246);
  --turquoise: rgba(60, 214, 224, 1);
  --light-turquoise: rgba(251, 255, 255, 1);
  --grey-blue: rgba(107, 152, 170, 1);
  --light-grey-blue: rgba(250, 253, 255, 1);
  --grey: #cfcfcf;
  --light-grey: rgba(207, 207, 207, 0.5);
  --dark-grey: #555;
  --light-grey2: #ecf1f7;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 27px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: initial;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.mail-scan-wrapper {
  display: flex;
  flex-direction: column;
}

.yellow {
  color: var(--yellow);
}

.break-spaces {
  white-space: break-spaces;
}

@layer reset, base, tokens, recipes, utilities;
